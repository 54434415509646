import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('div',{staticClass:"auth-inner"},[_c(VRow,{staticClass:"auth-row ma-0"},[_c(VCol,{staticClass:"d-none d-lg-block position-relative overflow-hidden pa-0",attrs:{"lg":"8"}},[_c('div',{staticClass:"auth-illustrator-wrapper"},[_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"362","src":require(("@/assets/images/misc/mask-v2-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}}),_c(VImg,{staticClass:"auth-tree",attrs:{"height":"226","width":"300","src":require("@/assets/images/misc/tree-4.png")}}),_c('div',{staticClass:"d-flex align-center h-full pa-16 pe-0"},[_c(VImg,{staticClass:"auth-3d-group",attrs:{"contain":"","max-width":"100%","height":"692","src":require(("@/assets/images/3d-characters/n-group-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}})],1)],1)]),_c(VCol,{staticClass:"d-flex align-center auth-bg pa-10 pb-0",attrs:{"lg":"4"}},[_c(VRow,[_c(VCol,{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v("Welcome to "+_vm._s(_vm.appName)+" 👋🏻")]),_c('p',{staticClass:"mb-2"})]),_c(VCardText,[_c(VForm,{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c(VTextField,{staticClass:"mb-6",attrs:{"outlined":"","label":"User Name","placeholder":"User Name","error-messages":_vm.errorMessages.username,"rules":[_vm.validators.required, _vm.validators.usernameValidator(_vm.username, 4)],"hide-details":"auto"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,{staticClass:"mb-2",attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password","error-messages":_vm.errorMessages.password,"placeholder":"Password","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"rules":[_vm.validators.required],"hide-details":"auto"},on:{"keydown":_vm.checkCapslock,"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible},"blur":function($event){_vm.capsTooltip = false}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}]),model:{value:(_vm.capsTooltip),callback:function ($$v) {_vm.capsTooltip=$$v},expression:"capsTooltip"}},[_c('span',[_vm._v("Caps lock is On")])]),_c('div',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c(VCheckbox,{staticClass:"mt-0",attrs:{"hide-details":"","label":"Remember Me"},model:{value:(_vm.rememberme),callback:function ($$v) {_vm.rememberme=$$v},expression:"rememberme"}}),_c(VBtn,{staticClass:"mt-6",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"block":"","color":"primary","type":"submit"}},[_vm._v(" Login ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }